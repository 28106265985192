<template>
  <div class="E3D-video-Detail">
    <div v-loading="loading"></div>
    <div v-if="!loading" class="content">
      <breadcrumb :refer="refer"></breadcrumb>
      <div class="Detail-title" v-if="Detail.title"> {{ Detail.title }} </div>
      <div class="mb15 co-999" v-if="Detail.abstract"> {{ Detail.abstract }} </div>
      <div class="Videos" @contextmenu.stop="$event.returnValue = false">
        <div v-if="!video.startPlay" class="Videos-shade flex0">
          <img class="cur" src="~@/assets/PC/fenceDetail/player.png" alt="" @click="startVideo">
        </div>
        <video-player class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="false"
          :options="playerOptions"
          @statechanged="playerStateChanged($event)"
          >
        </video-player>
      </div>
    </div>
    <xy-alert-box
      v-show="AlertData.state"
      :title="AlertData.title"
      :imgUrl="AlertData.imgUrl"
      :btns="AlertData.btns"
      @alertClose="alertClose"
    >
    </xy-alert-box>
  </div>
</template>

<script>
import breadcrumb from '@/components/Breadcrumb/index.vue'
import xyAlertBox from '@/components/xyAlertBox'

import { E3DCourse, Learning } from '@/api/E3D.js'
import { GetShareData, GetWxShares } from '@/utils/common'
import { mapState } from 'vuex';
export default {
  name: 'E3DVideoDetail',
  data () {
    return {
      loading: true,
      // !面包屑
      refer: [
        { name: '首页', href: '/' },
        { name: 'E3D视频', href: '/Home/E3DVideo' },
        { name: '', href: ''}
      ],
      // !video 组件配置
      playerOptions: {
        playbackRates: [1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: '' // url地址
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      },
      video: {
        // @ 视频是否播放
        startPlay: false,
        // @ 当前视频播放倍速
        rate: 1,
        // @ 定时器秒数
        setTime: 0
      },
      // ! 详情数据
      Detail: {
        id: '',
        title: '',
        abstract: '',
      },
      params: {
        // !视频播放时长
        watchTime: 0,
        // !id
        id: this.$route.query.id
      },
      // ! 徽章弹窗
      AlertData: {
        state: false,
        title: '',
        imgUrl: '',
        btns: [
          {
            id: 1,
            url: '/Home/Profile/badge',
            title: '查看勋章',
            IsLink: false
          }
        ]
      }
    }
  },
  mounted() {
    if(!this.token) {
      this.$toast('请先登录再来浏览精彩视频吧！')
      this.$router.push('/Home/E3DVideo')
      return false
    }
    this.VideoInit()
    GetShareData(1, "E3DVideoDetail", this.$route.query.id)
  },
  methods: {
    // ! 点击开始播放
    startVideo() {
      this.$refs.videoPlayer.player.play();
      this.video.startPlay = true;
    },
    // ! 获取当前播放倍速
    VideoInit() {
      E3DCourse({id: this.$route.query.id}).then(res => {
        if (res.status === 1) {
          const share = {
            title: res.data.title,
            // desc: res.data.abstract
          }

          GetWxShares(share)
          this.refer[this.refer.length - 1].name = res.data.title
          this.Detail = res.data;
          this.playerOptions.sources[0].src = res.data.videoUrl;
          this.loading = false;
          this.$nextTick(()=> {
            const Video = document.querySelector('.vjs-playback-rate-value');
            Video.addEventListener('DOMNodeInserted',e => {
              this.video.rate = e.target.data.split('x')[0] * 1;
            });
          })
        } else {
          this.$toast(res.message);
          this.timeout = setTimeout(()=> {
            this.$router.push('/Home/E3DVideo')
          }, 300)
        }
      })
    },
    // ! 状态监听
    playerStateChanged(e) {
      if(e.play || e.playing) {
        if(!this.timer) {
          this.setTime()
        }
      } else if(e.waiting || e.pause) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    // ! 读取播放时长
    setTime() {
      this.timer = setInterval(()=>{
        this.video.setTime++;
        this.params.watchTime = this.params.watchTime + 1 * this.video.rate;
        if(this.video.setTime % 5 == 0) {
          this.setLearning()
        }
      },1000)
    },
    // ! 学习进度持续回调
    setLearning() {
      const Time = JSON.parse(JSON.stringify(this.params))
      this.params.watchTime = 0
      Learning(Time).then(res => {
        if (res.status === 1) {
          this.alertBox(res.data)
        }
      })
    },
    // ! 获取勋章弹窗
    alertBox(imgUrl) {
      if (imgUrl.length > 0 && imgUrl[0].imgUrl) {
        // this.AlertData.title = '恭喜您获得“' +  title + '”成就勋章！'
        this.AlertData.title = '恭喜您获得新的成就勋章！'
        this.AlertData.imgUrl = imgUrl
        this.AlertData.state = true
      }
    },
    // ! 关闭弹窗
    alertClose() {
      this.AlertData.state = false
    }
  },
  components: {
    breadcrumb,
    xyAlertBox
  },
  computed: {
    ...mapState(['token'])
  },
  beforeDestroy() {
    clearInterval(this.timer)
    clearInterval(this.timeout)
    this.timer = null
  }
}
</script>

<style lang="scss" scoped>
  @import './index.scss'
</style>